export default {
  pageName: "Kredit ohne Schufa",
  questions: [
    {
      question: "Zu welcher Jahresgruppe gehören Sie?",
      answers: [
        { text: "18 bis 24 Jahre", value: "no" },
        { text: "25 bis 55 Jahre", value: "yes" },
        { text: "56 bis 64 Jahre", value: "not" },
      ],
    },
    {
      question: "Sind Sie erwerbstätig ?",
      answers: [
        { text: "Ja", value: "yes" },
        { text: "Nein", value: "no" },
      ],
    },
    {
      question: "Wie hoch ist Ihr monatlicher Gehalt?",
      answers: [
        { text: "400 bis 750 €", value: "no" },
        { text: "751 bis 1500 €", value: "yes" },
        { text: "1501 bis 3800 €", value: "okay" },
      ],
    },
  ],
};
